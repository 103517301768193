<template>
  <section v-if="dictionary && Object.keys(dictionary).length > 0">
    <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/warehouse"
            ><a>{{ dictionary["warehouse.title"] }}</a></router-link
          >
          <router-link tag="li" to="/warehouse/add" class="is-active"
            ><a>Dodaj</a></router-link
          >
        </ul>
      </nav>
    </portal-target>

    <WarehouseForm />
  </section>
</template>

<script>
import WarehouseForm from "@/views/Records/Warehouse/WarehouseForm";
import { mapGetters } from "vuex";

export default {
  name: "ServicesAdd",
  components: {
    WarehouseForm,
  },
  computed: {
    ...mapGetters({
      dictionary: "dictionary",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
