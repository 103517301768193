<template>
  <section v-if="dictionary && Object.keys(dictionary).length > 0">
    <div class="ym-toptitle-box">
      <fixed-header :threshold="119" fixedClass="ym-toptitle-fixed">
        <div id="toptitle" class="level ym-toptitle">
          <h2 class="title level-left">
            {{ dictionary["warehouse.title"] }}
          </h2>
          <div v-if="!isDistributor" class="buttons level-right">
            <router-link
              tag="a"
              class="button is-medium is-dark"
              to="/warehouse/add"
              ><icon name="plus"></icon><span>Dodaj</span></router-link
            >
          </div>
        </div>
      </fixed-header>
    </div>
    <div class="columns is-variable is-6 is-multiline">
      <div class="column is-12">
        <div class="ym-searchbox">
          <b-input
            ref="searchInput"
            v-model="searchQuery"
            size="is-medium"
            placeholder="SZUKAJ..."
          ></b-input>
        </div>
        <ve-table
          ref="tableRef"
          border-y
          :columns="columns"
          :table-data="warehouse"
          :virtual-scroll-option="virtualScrollOption"
          :cellSelectionOption="cellSelectionOption"
          :rowStyleOption="rowStyleOption"
          :sort-option="sortOption"
          row-key-field-name="rowKey"
          max-height="calc(100vh - 330px)"
        />
      </div>
    </div>
  </section>
</template>

<script>
import FixedHeader from "vue-fixed-header";
import { mapActions, mapGetters } from "vuex";
import debounce from "@/utils/debounce";

export default {
  name: "Warehouse",
  components: {
    FixedHeader,
  },
  data() {
    return {
      warehouse: [],
      sourceData: [],
      loading: false,
      searchQuery: "",
      startRowIndex: 0,
      virtualScrollOption: {
        enable: true,
        scrolling: this.scrolling,
      },
      columns: [],
      rowStyleOption: {
        clickHighlight: false,
        hoverHighlight: true,
      },
      cellSelectionOption: {
        // disble cell selection
        enable: false,
      },
      sortOption: {
        multipleSort: true,
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
    };
  },
  watch: {
    searchQuery() {
      this.debouncedSearchQuery();
    },
  },
  mounted() {
    // this.$refs.searchInput.$el.children[0].focus()
  },
  created() {
    this.debouncedSearchQuery = debounce(this.searchInTable, 500);
    this.getWarehouse();
    document.addEventListener("keyup", this.tableNav);
    Array.from(document.querySelectorAll("table tbody tr")).forEach((node) => {
      node.classList.add("normal");
    });
  },
  destroyed() {
    document.removeEventListener("keyup", this.tableNav);
  },
  computed: {
    ...mapGetters({
      dictionary: "dictionary",
      user: "auth/user",
    }),
    isDistributor() {
      return this.user.function_role === "distributor";
    },
  },
  methods: {
    ...mapActions({
      getWarehouseCall: "records/getWarehouse",
    }),
    getWarehouse() {
      this.loading = true;

      this.getWarehouseCall()
        .then((resp) => {
          if (!resp.error) {
            for (const header of resp.data.header[0]) {
              this.columns.push({
                field: header,
                key: header,
                title: header,
                align: "left",
                sortBy: "",
              });
            }
            this.warehouse = resp.data.body.map((item, index) => {
              const obj = {};
              obj.rowKey = index;
              this.columns.forEach((column, index) => {
                obj[column.key] = item[index];
              });
              return obj;
            });
            this.sourceData = this.warehouse;
            // for (const body of resp.data.body) {

            // }
            // this.warehouse = resp.data.body;
          }
        })
        .catch(() => {
          this.warehouse = [];
        })
        .finally(() => (this.loading = false));
    },
    searchInTable() {
      const searchValue = this.searchQuery;

      const searchValueTrimmed = searchValue.trim();
      const searchValueRegex = /^"(.+)"$/.exec(searchValueTrimmed);
      const searchValueExact = searchValueRegex
        ? searchValueRegex[1]
        : searchValueTrimmed;

      this.warehouse = this.sourceData.filter(
        (x) =>
          !searchValueExact.length ||
          Object.values(x)
            .slice(1)
            .find((value) =>
              searchValueRegex
                ? value.toString().toLowerCase() ===
                  searchValueExact.toLowerCase()
                : value
                    .toString()
                    .toLowerCase()
                    .includes(searchValueExact.toLowerCase())
            )
      );
    },
    sortChange(params) {
      let data = this.warehouse.slice(0);

      for (const param in params) {
        if (params[param] !== "") {
          data.sort((a, b) => {
            const aNum = parseFloat(a[param]);
            const bNum = parseFloat(b[param]);
            if (params[param] === "asc") {
              return isNaN(aNum) || isNaN(bNum)
                ? a[param].localeCompare(b[param])
                : aNum - bNum;
            } else if (params[param] === "desc") {
              return isNaN(aNum) || isNaN(bNum)
                ? b[param].localeCompare(a[param])
                : bNum - aNum;
            } else {
              return 0;
            }
          });
        }
      }

      this.warehouse = data;
    },
  },
};
</script>

<style lang="scss" scoped></style>
