<template>
  <div v-if="dictionary && Object.keys(dictionary).length > 0">
    <ValidationObserver ref="formObserver" v-slot="{ errors }">
      <form
        class="login"
        @submit.prevent="onSubmitScroll"
        enctype="multipart/form-data"
      >
        <div class="ym-toptitle-box">
          <fixed-header :threshold="119" fixedClass="ym-toptitle-fixed">
            <div id="toptitle" class="level ym-toptitle">
              <div class="level-left">
                <router-link
                  tag="a"
                  to="/warehouse"
                  class="button xbtn is-medium is-transparent"
                >
                  <icon name="x"></icon>
                </router-link>
                <h2 class="title" v-if="isNew">Dodaj</h2>
                <h2 class="title" v-else>Edytuj</h2>
              </div>
              <div class="buttons level-right">
                <button class="button is-medium is-primary">
                  <icon name="check"></icon><span>Wyślij</span>
                </button>
              </div>
            </div>
          </fixed-header>
        </div>
        <div
          :class="
            errors['file'] && errors['file'].length > 0
              ? 'columns is-variable is-6 is-multiline v-field v-error'
              : 'columns is-variable is-6 is-multiline'
          "
        >
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_company_short">Plik</label>
              <span
                v-if="errors['file'] && errors['file'].length > 0"
                class="help is-danger"
                >{{ errors["file"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="file">
                  <b-upload
                    v-model="file"
                    drag-drop
                    class="file-label"
                    style="margin-bottom: 1rem"
                  >
                    <section class="section" style="padding: 3rem">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon
                            icon="upload"
                            size="is-large"
                            style="position: relative"
                          />
                        </p>
                        <p>Upuść plik tutaj lub kliknij, aby przesłać</p>
                      </div>
                    </section>
                  </b-upload>
                </ValidationProvider>
                <div v-if="file" class="card">
                  <div class="card-content">
                    <p class="label is-uppercase">Dodano: {{ file.name }}</p>
                    <div>{{ dictionary["warehouse.file.info"] }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import FixedHeader from "vue-fixed-header";
import { mapGetters } from "vuex";
import { $axios } from "@/utils/axios";

import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "WarehouseForm",
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    FixedHeader,
    ValidationObserver,
    ValidationProvider,
  },
  data: function () {
    return {
      file: null,
    };
  },
  computed: {
    ...mapGetters({
      dictionary: "dictionary",
    }),
    isNew() {
      return this.id === 0 ? true : false;
    },
  },
  methods: {
    onSubmitScroll() {
      this.$refs.formObserver.validate().then((success) => {
        if (!success) {
          for (const key of Object.keys(
            this.$refs.formObserver.fields
          ).sort()) {
            if (this.$refs.formObserver.fields[key].invalid) {
              const y =
                this.$refs.formObserver.refs[key].$el.getBoundingClientRect()
                  .top;
              this.$refs.formObserver.refs[key].$el.scrollIntoView({
                top: y,
                behavior: "smooth",
              });
              return;
            }
          }
        }

        this.onSubmit();
      });
    },
    onSubmit() {
      this.$store.dispatch("setLoading", true, { root: true });

      const formData = new FormData();
      formData.append("file", this.file);

      $axios
        .post(`/web/warehouse/file`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$store.dispatch("setLoading", false, { root: true });

          this.$router.push({
            name: "Warehouse",
          });
        });
    },
  },
  mounted() {},
  destroyed() {},
};
</script>

<style scoped>
.buttons-wrap {
  margin-top: 8px;
  flex-wrap: wrap;
}
</style>
